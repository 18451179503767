<template>
  <div>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div v-if="!quoteSent">
        <v-row class="mb-10 justify-center">
          <v-col cols="12" md="8" lg="6">
            <div class="text-center">
              <h2 class="mb-2">Request a Quote</h2>
              <p class="grey--text mb-7">
                Let us know what is your needs to be done.
              </p>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    required
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="company"
                    label="Company"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    required
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Phone number"
                    type="number"
                    v-model="phone"
                    :rules="phoneRules"
                    required
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mb-8" />
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-select
                    v-model="product"
                    :items="products"
                    item-text="text"
                    item-value="value"
                    :rules="[(v) => !!v || 'Products is required']"
                    label="Products"
                    required
                    filled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Date needed"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="menu2 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Quantity (in containers)"
                    type="number"
                    v-model="quantity"
                    :rules="quantityRules"
                    required
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-select
                    v-model="size"
                    :items="containerSize"
                    :rules="[(v) => !!v || 'Container size is required']"
                    label="Container Size"
                    required
                    filled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-textarea
                    filled
                    name="input-7-4"
                    label="Message"
                    v-model="message"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-file-input
                    accept="application/msword, application/vnd.ms-excel,
                    application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                    label="Attachment"
                    filled
                  ></v-file-input>
                </v-col>
                <v-col class="d-flex justify-end py-0">
                  <v-btn
                    class="btn-full"
                    :disabled="!valid || sending"
                    color="primary"
                    :loading="sending"
                    @click="sendQuote"
                    >Send Quote</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <div class="text-center ma-2">
          <v-snackbar v-model="quoteFailed" color="red accent-2">
            Failed to send quote. Please try again.

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="quoteFailed = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
      <div v-else class="d-flex justify-center">
        <div class="text-center">
          <v-img
            :src="require('../assets/send-1.svg')"
            class="my-3"
            contain
            height="128"
          />
          <h2>Your quote has been sent</h2>
          <p class="grey--text">
            You will receive an email if and when your quote is approved,
            <br />
            and we can continue to discuss your needs.
          </p>
          <v-btn color="primary" to="/" class="mt-2">Back to Home</v-btn>
          <p class="grey--text mt-5 mb-2">Or</p>
          <v-btn color="primary" text @click="quoteSent = false"
            >Send another quote</v-btn
          >
        </div>
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import "isomorphic-fetch";
import { SERVER_HOST } from "../../server/utils/env.json";

export default {
  components: {
    Footer,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Request a Quote",
        disabled: true,
        href: "/request-a-quote",
      },
    ],
    sending: false,
    quoteFailed: false,
    quoteSent: false,
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone number is required",
      (v) => (v && v.length > 5) || "Phone number must be at least 5 digits",
      (v) => (v && v.length <= 16) || "Phone must be less than 16 digits",
    ],
    quantity: "",
    quantityRules: [
      (v) => !!v || "Quantity is required",
      (v) => (v && v < 100) || "Quantity must be less than 100 containers",
    ],
    size: "",
    sizeRules: [
      (v) => !!v || "Size is required",
      (v) => (v && v <= 10000) || "Size must be less than 10000 kgs",
    ],
    company: "",
    message: "",
    products: [
      {
        text: "Corn Starch",
        value: "corn-starch",
      },
      {
        text: "Maltodextrin",
        value: "maltodextrin",
      },
      {
        text: "High Fructose Corn Syrup",
        value: "high-fructose-corn-syrup",
      },
      {
        text: "Corn Germ",
        value: "corn-germ",
      },
      {
        text: "Corn Gluten Feed",
        value: "corn-gluten-feed",
      },
      {
        text: "Corn Gluten Meal",
        value: "corn-gluten-meal",
      },
      {
        text: "Corn Steep Liquor",
        value: "corn-steep-liquor",
      },
    ],
    containerSize: ["20 feet", "40 feet"],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    product: null,
  }),

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    sendQuote() {
      const content = {
        name: this.name,
        company: this.company,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };

      this.$refs.form.validate();

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(content),
        redirect: "follow",
      };

      if (this.$refs.form.validate()) {
        this.sending = true;
        fetch(`${SERVER_HOST}/api/email`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log("result: ", result);
            this.sending = false;
            if (result.status === "Success") {
              this.$refs.form.reset();
              this.quoteSent = true;
              window.scrollTo(0, 0);
              console.log("Email sent.");
            } else {
              console.log("Failed to send email.");
              this.quoteFailed = true;
            }
          })
          .catch((err) => {
            this.sending = false;
            this.quoteFailed = true;
            console.log("error: ", err);
          });
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style scoped>
@media (max-width: 1264px) {
  .btn-full {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: auto;
  }
}
</style>
